import React, { useEffect, useState } from 'react'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import keycloak from './keyclock'
import { useKeycloak } from '@react-keycloak/web'

function App() {
  const [token, setToken] = useState();
  const [msg,setMsg]=useState()
 // const [keycloak,setKeyclok]=useState()
  //const {keycloak}=useKeycloak();
  function callE_1() {
    console.log('EW1.0 called')
    setMsg('EW1.0 called')
  }
  function callE_2() {
    console.log('EW2.0 called');
    setMsg("EW2.0 called");
    window.location.href="https://smiles.in/Ew2/#/Layout"
  }
  function Init() {
    console.log('Inside Init')
    var url_string = window.location.href
    if (url_string.split('?').length > 1) {
      callE_1()
    } else {
      callE_2()
    }
  
  }
  useEffect(()=>{
    Init();
   
  })
  function Logout(){
    //setKeyclok(useKeycloak().keycloak);
    const {keycloak}=useKeycloak();
    function onlogout(){
      keycloak.logout();
    }
    return(
      <>
      <button onClick={()=>onlogout()}>Logout</button>
      </>
    )
  }
  return (
    <ReactKeycloakProvider
      onAuthError={(e) => console.log('Key error: ', e)}
      onEvent={(event, error) =>
        console.log('From Keyclock1 :', event, ' ', error)
      }
      authClient={keycloak}
      initOptions={{ onLoad: 'login-required' }}
      onTokens={(res) => {
        console.log('From Token handler ', res.token)
        setToken(res.token)
      }}
    >
      {/* {token &&  setKeyclok(useKeycloak().keycloak)} */}
      {/* {Init()} */}
      <h1>{msg}</h1>
      {token && <Logout />}
    </ReactKeycloakProvider>
  )
}

export default App
